<template>
  <div id="app">
    <el-row type="flex" justify="center">
      <el-col :xs="24" :md="18" :lg="16" :xl="12">
        <router-view />
      </el-col>
    </el-row>
  </div>
</template>

<style lang="scss">
#app {
  // font-family: "Avenir", Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  background-color: #2f5bc7;
  color: #fff;
  min-height: 100vh;
}
</style>

<script>
export default {
  created() {
    this.$store.dispatch("fetchCoreQuestions");
    this.$store.dispatch("fetchDTQuestions");
  }
};
</script>
