import Vue from "vue";
import VueRouter from "vue-router";
import Landing from "../views/Landing.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "landing",
    component: Landing
  },
  {
    path: "/analysis",
    name: "analysis",
    component: () => import("@/views/Analysis.vue")
  },
  {
    path: "/intelligent-analysis",
    name: "intelligent-analysis",
    component: () => import("@/views/IntelligentAnalysis.vue")
  },
  {
    path: "/analysising",
    name: "analysising",
    component: () => import("@/views/Analysising.vue")
  },
  {
    path: "/result",
    name: "result",
    component: () => import("@/views/Result.vue"),
    beforeEnter(to, from, next) {
      if (!store.getters.allowEnterResultPage) {
        next("/analysis");
      } else {
        next();
      }
    }
  },
  { path: "*", component: Landing }
  // {
  //   path: "/signup",
  //   name: "signup",
  //   component: () => import("@/views/Signup.vue")
  // }
];

const router = new VueRouter({
  routes
});

export default router;
