<template>
  <div>
    <!-- Modal Placeholder -->
    <!-- <LoginModal
      v-show="shouldDisplayLoginModal"
      @close="shouldDisplayLoginModal = false"
    ></LoginModal> -->
    <SideMenuModal
      v-show="shouldDisplaySideMenuModal"
      @close="onSideMenuClosed($event)"
    ></SideMenuModal>

    <!-- Header -->
    <header v-show="scrollShow" class="section-padding page-header">
      <div class="mobile">
        <img class="page-header__logo" src="@/assets/img-01.png" />
      </div>
      <div class="mobile" @click="onSideMenuClicked">
        <img class="page-header__side-menu" src="@/assets/side-menu.png" />
      </div>
      <div class="pc">
        <div v-show="scrollShow">
          <img class="page-header__logo" src="@/assets/img-01.png" />
        </div>
      </div>
      <div class="pc">
        <div v-show="scrollShow">
          <div class="flex-row">
            <div class="item-pc" @click="goslid(1)">平台簡介</div>
            <div class="item-pc" @click="goslid(2)">體質簡介</div>
            <div class="item-pc" @click="goslid(3)">聯絡我們</div>
            <div class="item-pc active" @click="onTryBtnClicked" type="success">
              立即體驗
            </div>
          </div>
        </div>
      </div>
    </header>

    <!-- 平台簡介 -->
    <section class="section-padding platform-description" ref="platform">
      <div class="pc">
        <div
          class="pc-platform-box flex-col flex-justify-center flex-align-center"
        >
          <img width="250" height="50" src="@/assets/img-01.png" />
          <div class="item flex-row flex-justify-center" style="width:100%">
            <div class="items" @click="goslid(1)">平台簡介</div>
            <div class="items" @click="goslid(2)">體質簡介</div>
            <div class="items" @click="goslid(3)">聯絡我們</div>
          </div>
        </div>
      </div>
      <div class="platform-description__subtitle1">追蹤個人體質變化</div>
      <div class="platform-description__subtitle2">管理自己健康生活</div>
      <el-row>
        <el-col :xs="24" :md="18" :lg="12">
          <div class="platform-description__desc-text">
            聯通
            <span class="bold-text">香港中文大學</span>
            與
            <span class="bold-text">北京中醫藥大學</span>

            <span class="bold-text">智慧醫療</span
            >體質數據庫，經本地研發的雲端平台— 運算用戶所輸入的對答，
            <span class="bold-text">迅速準確分辨出你的個人體質</span>。
          </div>
          <div>
            <el-button
              class="full-width-btn"
              type="success"
              @click="onTryBtnClicked"
              >完整版</el-button
            >
            <el-button
              class="full-width-btn"
              type="success"
              @click="onShortVersionBtnClicked"
              >簡短版</el-button
            >
          </div>
        </el-col>
      </el-row>
    </section>

    <!-- 系統簡介 -->
    <section class="section-padding system-description" ref="system">
      <el-row>
        <el-col :xs="24" :md="12" :lg="12" :xl="10">
          <div class="system-description__image-container">
            <img class="system-description__image" src="@/assets/img-02.png" />
          </div>
        </el-col>
        <el-col :xs="24" :md="12" :lg="12" :xl="14">
          <div class="section-title-text">體質預診</div>
          <div>
            透過使用本系統，你將得到屬於你自己的體質分析數據，有助你了解自己的身體變化；
            你亦可以自由選擇將數據分享給主診醫生，從而令醫護更了解你的健康變化，做出更合適的診療方案。
          </div>
        </el-col>
      </el-row>
    </section>

    <!-- 九型體質 -->
    <section class="section-padding diagnosis-description" ref="physical">
      <div class="section-title-text">中醫九型體質</div>
      <div class="diagnosis-description__desc-text">
        跟據北京中醫藥大學國家中醫體質與治未病研究院
        王琦院長所建立的中醫體質學裡的描述，人體可分為九種體質型態。
        在實踐中認識到體質不是固定不變的，外界環境與生活習慣，都有機會令體質改變。
        對於不良體質，通過有計劃地改變生活起居、飲食、運動等積極養生措施，可將偏頗體質糾正，改善與調整身體能，達到增強抗病力，防病延年目的。
      </div>
      <!-- <div>
        <el-button
          v-if="!hasLoggedIn"
          class="full-width-btn"
          type="success"
          @click="onSignupBtnClicked"
          >免費登記成為用戶</el-button
        >
      </div> -->
    </section>

    <!-- 宣傳 -->
    <section class="section-padding promotion-section">
      <el-row>
        <el-col :xs="24" :md="12" :lg="8">
          <div class="promotion-section__image-container">
            <img class="promotion-section__image" src="@/assets/img-03.png" />
          </div>
        </el-col>
        <el-col :xs="24" :md="12" :lg="16">
          <div class="section-title-text">你認為自己是屬於什麼體質呢？</div>
          <div>
            <li>只需要簡單回答幾條問題，即可得到分析結果</li>
            <li>所有資料均會去除個人資料，並受系統加密保護</li>
            <li>完成分析後，使用者可自由選擇將數據分享給自己或分享給其他人</li>
          </div>
        </el-col>
      </el-row>
    </section>

    <!-- 合作夥伴 -->
    <section class="section-padding partner-section">
      <div class="section-title-text">合作夥伴</div>
      <el-row>
        <el-col
          :xs="12"
          :sm="6"
          v-for="(partner, index) in partners"
          :key="index"
        >
          <div class="text-center">
            <img class="partner-section__image" :src="partner.imageSrc" />
          </div>
          <div class="text-center partner-section__name">
            {{ partner.name }}
          </div>
          <div class="text-center partner-section__desc">
            {{ partner.desc }}
          </div>
        </el-col>
      </el-row>

      <!-- 聯絡我們表單 -->
      <div class="contact-us-container" ref="contact">
        <div>
          <div class="section-title-text">聯絡我們</div>
          <div>如有興趣了解更多資訊，歡迎與我們團隊聯絡。</div>
          <div class="contact-us-container__input-form">
            <el-form
              :model="enquiryForm"
              :rules="enquiryFormRules"
              ref="enquiryForm"
            >
              <el-form-item prop="contactPerson">
                <el-input
                  class="contact-us-container__input"
                  placeholder="輸入名稱"
                  prefix-icon="el-icon-user"
                  v-model="enquiryForm.contactPerson"
                ></el-input>
              </el-form-item>
              <el-form-item prop="contactEmail">
                <el-input
                  class="contact-us-container__input"
                  placeholder="輸入聯絡電郵"
                  prefix-icon="el-icon-message"
                  v-model="enquiryForm.contactEmail"
                ></el-input>
              </el-form-item>
              <el-form-item prop="message">
                <el-input
                  class="contact-us-container__input"
                  type="textarea"
                  :rows="2"
                  placeholder="輸入你的訊息"
                  v-model="enquiryForm.message"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div>
            <el-button
              class="full-width-btn"
              type="success"
              @click="onInquiryBtnClicked"
              >送出訊息</el-button
            >
          </div>
        </div>
      </div>
    </section>
    <!-- 頁脚部分 -->
    <div class="pc">
      <div class="footer flex-row flex-justify-between">
        <div class="title">智慧醫聊預診</div>
        <div class="flex-col flex-justify-start">
          <span @click="goslid(1)">平台首頁</span>
          <span @click="goslid(2)">體質介紹</span>
          <span @click="goslid(3)">聯絡我們</span>
        </div>
        <div class="flex-col flex-justify-start">
          <span>服務條款</span>
          <span>隱私政策</span>
        </div>
        <div class="flex-col flex-justify-between">
          <div class="flex-row flex-justify-end">
            <!-- <img width="35" height="35" src="@/assets/partner-4.png" />
            <img width="35" height="35" src="@/assets/partner-4.png" />
            <img width="35" height="35" src="@/assets/partner-4.png" />
            <img width="35" height="35" src="@/assets/partner-4.png" /> -->
          </div>
          <div class="sm-footer">所有版權屬DOCTORBOT LIMTED所有</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page-header {
  position: sticky;
  top: 0px;
  z-index: 100;

  padding: 15px 60px;
  @media screen and (max-width: 576px) {
    padding: 15px 20px;
  }

  display: flex;
  justify-content: space-between;
  background-color: #6b87ff;
  align-items: center;

  &__logo {
    width: 120px;
    height: 25px;
  }

  &__side-menu {
    width: 25px;
    height: 25px;
  }
}
.platform-description {
  background: linear-gradient(#6b87ff, #6146df);

  &__subtitle1 {
    font-size: 36px;
    font-weight: 500;
  }

  &__subtitle2 {
    font-size: 36px;
  }

  &__desc-text {
    margin: 18px 0px 36px 0px;
  }
}

.system-description {
  background: linear-gradient(#595edb, #5650d4);

  &__image-container {
    padding: 20px 0px;
    text-align: center;
  }

  &__image {
    width: 320px;
    height: 80px;
  }
}

.diagnosis-description {
  background: linear-gradient(#6255e0, #3b7cd2);

  &__desc-text {
    margin: 18px 0px 36px 0px;
  }
}

.promotion-section {
  background: linear-gradient(#3472c8, #238ec3);

  &__image-container {
    padding: 20px 0px;
    text-align: center;
  }

  &__image {
    width: 150px;
    height: 150px;
  }
}

.partner-section {
  background: linear-gradient(#279dcc, #3767c5);
  &__image {
    width: 150px;
    height: 150px;
  }

  &__name {
    white-space: pre-line;
  }

  &__desc {
    font-size: 14px;
    margin-top: 15px;
  }
}

.contact-us-container {
  margin: 30px 0px;
  background-color: #f6f6f6;
  color: #70798b;
  padding: 30px 20px;
  border-radius: 8px;

  &__input-form {
    margin: 20px 0px;
  }

  &__input {
    // padding: 8px 0px;
  }
}

.footer {
  height: 14vh;
  margin-top: 5vh;
  padding-bottom: 3vh;
  span {
    height: 35%;
    opacity: 0.8;
    cursor: pointer;
  }
  img {
    margin-left: 15px;
  }
  .title {
    font-weight: 550;
    font-size: 20px;
    letter-spacing: 2px;
  }
  .sm-footer {
    font-size: 14px;
  }
}

.pc-platform-box {
  img {
    margin: 30px 0 50px 0;
  }
  .item {
    margin-bottom: 50px;
    .items {
      padding: 8px 20px;
      font-weight: 500;
      cursor: pointer;
      // text-shadow: 2px 1px 2px #000;
    }
  }
}

@media screen and (max-width: 1080px) {
  .pc {
    display: none !important;
  }
  .mobile {
    display: block !important;
    touch-action: auto;
  }
}

@media screen and (min-width: 1081px) {
  .pc {
    display: block !important;
  }
  .mobile {
    display: none !important;
    touch-action: auto;
  }
  .item-pc {
    padding: 10px 10px;
    margin: 0 5px;
    font-weight: 400;
    cursor: pointer;
    &.active {
      background-color: #67c23a;
      border-radius: 8px;
    }
  }
}
</style>

<script>
import parnterOneImage from "@/assets/partner-1.png";
import parnterTwoImage from "@/assets/partner-2.png";
import parnterThreeImage from "@/assets/partner-3.png";
import parnterFourImage from "@/assets/partner-4.png";
// import LoginModal from "@/components/LoginModal.vue";
import SideMenuModal from "@/components/SideMenuModal.vue";
import { mapGetters } from "vuex";

export default {
  name: "landing",
  components: {
    // LoginModal,
    SideMenuModal
  },
  data() {
    return {
      shouldDisplayLoginModal: false,
      shouldDisplaySideMenuModal: false,
      constitutions: [
        {
          title: "平和質",
          desc:
            "體形勻稱健壯，皮膚潤澤，頭髮稠密，目光有神，嗅覺通利，唇色紅潤，不易疲勞，精力充沛，耐受寒熱，睡眠良好，胃口好，二便正常，舌色淡紅，舌苔薄白，脈和緩有力。性格隨和開朗，平素少病，對外界環境適應良好。"
        },
        {
          title: "氣虛質",
          desc:
            "肌肉鬆軟不實，語音偏弱，氣促，容易疲乏，精神不振，易出汗，舌淡紅，舌邊有齒痕，脈弱。性格內向，不喜冒險。易患感冒或內臟下垂等，病後康復緩慢，不易適應風寒、暑濕環境。"
        },
        {
          title: "陽虛質",
          desc:
            "肌肉鬆軟不實，怕冷，手足冰冷，喜歡溫熱飲食，精神不振，舌淡胖嫩，脈沉遲。性格多沉靜、內向。易有痰多、浮腫、腹瀉問題。在夏天溫熱日子過得很好，冬天時易感風、寒或濕邪。"
        },
        {
          title: "陰虛質",
          desc:
            "體形偏瘦，手足心熱，口乾，鼻微乾燥，喜冷飲，大便乾燥，舌紅少津，脈細數。性情急躁，外向好動。易有虛勞、失精、失眠、咳嗽、乾燥綜合征等毛病。不易適應暑熱或乾燥天氣。"
        },
        {
          title: "痰濕質",
          desc:
            "身材肥胖，腹部肥滿鬆軟，面部油脂較多，汗多且黏，胸悶，痰多，口腔黏膩或甜，喜食肥膩甜點，舌苔膩，脈滑。性格偏溫和、穩重，有耐性，易有高血糖、代謝綜合征或心血管問題。在濕重環境適應不良。"
        },
        {
          title: "熱濕質",
          desc:
            "身材中等或偏瘦，面多油垢，易有暗瘡，口苦口乾，身重困倦，排便不暢或便秘，小便黃，男性陰囊潮濕，女性白帶增多，舌質偏紅，苔黃膩，脈滑數。容易心煩急躁。易有皮膚或小便問題。不易適應濕重或高溫環境。"
        },
        {
          title: "血瘀質",
          desc:
            "胖瘦均有，面色晦黯，易有色斑，口唇黯淡，身上或舌頭易見瘀斑、青筋，脈澀。容易煩躁，健忘。多有痛証、出血症狀或異常增生。對寒冷環境較不適應。"
        },
        {
          title: "氣鬱質",
          desc:
            "身體偏瘦者多，神情抑鬱，情感脆弱，煩悶不樂，容易心慌和失眠，舌淡紅，苔薄白，脈弦。內向不穩定、敏感多慮。易有情緒問題、神經衰弱、失眠或乳腺增生。不適應陰雨天氣。"
        },
        {
          title: "特稟質",
          desc:
            "一般無特殊體癥，可能有畸形或生理缺陷。多有過敏症，表現氣喘、癮疹、咽喉發癢、鼻塞、噴嚏等。易患哮喘、蕁麻疹、濕疹、花粉症、藥物過敏，或有遺傳病或發育問題等。對外界環境變化適應差。"
        }
      ],
      partners: [
        {
          name: "香港聖公會\n麥理浩夫人中心",
          imageSrc: parnterOneImage,
          desc: ""
        },
        {
          name: "黃韻婷助理教授",
          imageSrc: parnterTwoImage,
          desc: "香港中文大學中醫學院及香港中西醫結合醫學研究所助理教授"
        },
        {
          name: "農本方",
          imageSrc: parnterThreeImage,
          desc: ""
        },
        {
          name: "F5 WORKS LIMITED",
          imageSrc: parnterFourImage,
          desc: ""
        }
      ],
      enquiryForm: {
        contactPerson: "",
        contactEmail: "",
        message: ""
      },
      enquiryFormRules: {
        contactPerson: [
          { required: true, message: "必須填寫", trigger: "blur" }
        ],
        contactEmail: [
          { required: true, message: "必須填寫", trigger: "blur" }
        ],
        message: [{ required: true, message: "必須填寫", trigger: "blur" }]
      },
      scrollShow: false // 是否滚动出现
    };
  },
  methods: {
    onTryBtnClicked() {
      this.$router.push("analysis");
    },
    onShortVersionBtnClicked() {
      this.$router.push("intelligent-analysis");
    },
    onLoginBtnClicked() {
      this.shouldDisplayLoginModal = true;
    },
    onSignupBtnClicked() {
      this.$router.push("signup");
    },
    onSideMenuClicked() {
      this.shouldDisplaySideMenuModal = true;
    },
    onSideMenuClosed(event) {
      // Platform平台簡介  Physical體質簡介 Contact聯絡我們
      this.shouldDisplaySideMenuModal = false;
      if (event === "TO_LOGIN") {
        this.shouldDisplayLoginModal = true;
      }
      if (event === "Platform") {
        this.goslid(1);
      }
      if (event === "Physical") {
        this.goslid(2);
      }
      if (event === "Contact") {
        this.goslid(3);
      }
    },
    goslid(state) {
      // 1平台簡介  2體質簡介 3聯絡我們
      if (state === 1) {
        this.$refs.platform.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
      }
      if (state === 2) {
        this.$refs.physical.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
      }
      if (state === 3) {
        this.$refs.contact.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
      }
    },
    handleScroll() {
      // 首先修改相对滚动位置
      this.scrollTop = this.scrollTop =
        window.pageYOffset || document.body.scrollTop;
      if (this.scrollTop > 50) {
        this.scrollShow = true;
      } else {
        this.scrollShow = false;
      }
    },
    // 發出訊息
    onInquiryBtnClicked() {
      this.$refs["enquiryForm"].validate(valid => {
        if (valid) {
          this.$store.dispatch("enquiry", this.enquiryForm);
        }
      });
    }
  },
  mounted() {
    this.$nextTick(function() {
      //修改事件监听
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  computed: {
    ...mapGetters(["hasLoggedIn"])
  }
};
</script>
