<template>
  <div class="modal-backdrop" @click="emitCloseEvent">
    <div class="modal-body" role="dialog" @click.stop>
      <!-- <section class="action-bar">
        <el-button
          v-if="!hasLoggedIn"
          class="full-width-btn"
          type="success"
          @click="onLoginBtnClicked"
          >用戶登入</el-button
        >
        <el-button
          v-if="hasLoggedIn"
          class="full-width-btn"
          type="success"
          @click="onLogoutBtnClicked"
          >登出</el-button
        >
      </section> -->
      <section class="menu">
        <div
          class="menu__item"
          @click="
            state = 1;
            goslid();
          "
        >
          平台主頁
        </div>
        <div
          class="menu__item"
          @click="
            state = 2;
            goslid();
          "
        >
          體質分析
        </div>
        <div
          class="menu__item"
          @click="
            state = 3;
            goslid();
          "
        >
          聯絡我們
        </div>
        <div class="menu__item">服務條款</div>
        <div class="menu__item">私隱政策</div>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
  animation: fadeIn 0.3s;
}

.modal-body {
  height: 350px;
  width: 300px;
  border-radius: 8px;
  margin: 15px;
  padding: 15px;

  background-color: #fff;
  color: #414c64;
}

.action-bar {
  margin-top: 40px;
}

.menu {
  margin-top: 40px;
  &__item {
    cursor: pointer;
    border-bottom: 1px solid #e9e9e9;
    font-weight: 350;
    height: 50px;
    line-height: 50px;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(2, 2);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SideMenuModal",
  data() {
    return {
      state: 0
    };
  },
  methods: {
    onLoginBtnClicked() {
      this.$emit("close", "TO_LOGIN");
    },
    onLogoutBtnClicked() {
      this.$store.dispatch("logout");
      this.$emit("close", "LOGOUT");
    },
    emitCloseEvent() {
      this.$emit("close");
    },
    goslid() {
      // 1平台簡介  2體質簡介 3聯絡我們
      if (this.state == 1) {
        this.$emit("close", "Platform");
      }
      if (this.state == 2) {
        this.$emit("close", "Physical");
      }
      if (this.state == 3) {
        this.$emit("close", "Contact");
      }
    }
  },
  computed: {
    ...mapGetters(["hasLoggedIn"])
  }
};
</script>
