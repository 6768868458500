import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import { Message } from "element-ui";

Vue.use(Vuex);

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export default new Vuex.Store({
  state: {
    coreQuestionList: [],
    dtQuestionList: [],
    selectedValueDict: {},
    indexOfCurrentCoreQuestion: 0,
    indexOfCurrentDtQuestion: 0,
    detectedScores: [],
    allowEnterResultPage: false,
    isLoginFail: false,
    isSignupFail: false,
    userEmail: "",
    diagnosisId: -1
  },
  getters: {
    questionByNumber: state => number => {
      return state.coreQuestionList.find(item => item.number === number);
    },
    coreQuestionList: state => {
      return state.coreQuestionList;
    },
    dtQuestionList: state => {
      return state.dtQuestionList;
    },
    totalNumberOfCoreQuestions: state => {
      return state.coreQuestionList.length;
    },
    totalNumberOfDtQuestions: state => {
      return state.dtQuestionList.length;
    },
    currentDtQuestionNumber: state => {
      if (state.coreQuestionList.length > 0) {
        return state.dtQuestionList[state.indexOfCurrentDtQuestion].number;
      } else {
        return 0;
      }
    },
    selectedValueOfCurrentDtQuestion: (state, getters) => {
      return state.selectedValueDict[getters.currentDtQuestionNumber];
    },
    hasFinishedAllDtQuestions: state => {
      return (
        Object.keys(state.selectedValueDict).length ===
        state.dtQuestionList.length
      );
    },
    detectedScores: state => {
      return state.detectedScores;
    },
    allowEnterResultPage: state => {
      return state.allowEnterResultPage;
    },
    isLoginFail: state => {
      return state.isLoginFail;
    },
    isSignupFail: state => {
      return state.isSignupFail;
    },
    hasLoggedIn: () => {
      return localStorage.getItem("authToken");
    },
    userEmail: state => {
      return state.userEmail;
    }
  },
  mutations: {
    SET_CORE_QUESTION_LIST: (state, payload) => {
      state.coreQuestionList = payload;
    },
    SET_DT_QUESTION_LIST: (state, payload) => {
      state.dtQuestionList = payload;
    },
    UPSERT_ANSWERED_QUESTION: (state, payload) => {
      state.selectedValueDict = {
        ...state.selectedValueDict,
        [payload.questionNumber]: payload.selectedValue
      };
    },
    BUMP_INDEX_OF_CURRENT_DT_QUESTION: state => {
      if (state.indexOfCurrentDtQuestion < state.dtQuestionList.length) {
        state.indexOfCurrentDtQuestion += 1;
      }
    },
    REVERT_INDEX_OF_CURRENT_DT_QUESTION: state => {
      if (state.indexOfCurrentDtQuestion > 0) {
        state.indexOfCurrentDtQuestion -= 1;
      }
    },
    SET_DETECTED_SCORE: (state, payload) => {
      state.detectedScores = payload;
    },
    SET_DIAGNOSIS_ID: (state, payload) => {
      state.diagnosisId = payload;
    },
    ALLOW_ENTER_RESULT_PAGE: state => {
      state.allowEnterResultPage = true;
    },
    UPDATE_LOGIN_FAIL_FLAG: (state, payload) => {
      state.isLoginFail = payload;
    },
    UPDATE_SIGNUP_FAIL_FLAG: (state, payload) => {
      state.isSignupFail = payload;
    },
    CAPTURE_ENQUIRY_MESSAGE: (state, payload) => {
      if (payload) {
        Message.success("我們已收到您的訊息~");
      } else {
        Message.error("请重试~");
      }
    }
  },
  actions: {
    fetchCoreQuestions(context) {
      Vue.axios
        .get(
          `${process.env.VUE_APP_API_SERVER_URL}/question-banks?algoName=CCMQ60`
        )
        .then(res => {
          context.commit("SET_CORE_QUESTION_LIST", res.data[0].questions);
        });
    },
    fetchDTQuestions(context) {
      Vue.axios
        .get(
          `${process.env.VUE_APP_API_SERVER_URL}/question-banks?algoName=CCMQ_DT`
        )
        .then(res => {
          let dtQList = res.data[0].questions;
          shuffle(dtQList);
          context.commit("SET_DT_QUESTION_LIST", dtQList);
        });
    },
    bumpDtQuestionIndex(context) {
      context.commit("BUMP_INDEX_OF_CURRENT_DT_QUESTION");
    },
    revertDtQuestionIndex(context) {
      context.commit("REVERT_INDEX_OF_CURRENT_DT_QUESTION");
    },
    upsertAnsweredQuestion(context, payload) {
      context.commit("UPSERT_ANSWERED_QUESTION", payload);
    },
    validateQuestionForm(context) {
      const c = Object.keys(context.state.selectedValueDict);
      const d = context.state.coreQuestionList.map(item => `${item.number}`);

      let difference = d
        .filter(x => !c.includes(x))
        .concat(c.filter(x => !d.includes(x)));

      return difference;
    },
    getScores(context, payload) {
      let reqBody = {};
      if (payload.algoName === "CCMQ60") {
        reqBody = {
          type: "CCMQ60",
          input: context.state.coreQuestionList.map(item => {
            return {
              ...item,
              selectedValue: context.state.selectedValueDict[item.number]
            };
          })
        };
      } else if (payload.algoName === "CCMQ_DT") {
        reqBody = {
          type: "CCMQ_DT",
          input: context.state.dtQuestionList.map(item => {
            return {
              ...item,
              selectedValue: context.state.selectedValueDict[item.number]
            };
          })
        };
      }

      Vue.axios
        .post(`${process.env.VUE_APP_API_SERVER_URL}/diagnoses`, reqBody)
        .then(res => {
          const detectedScores = res.data.evaluation.output;
          context.commit("SET_DETECTED_SCORE", res.data.evaluation.output);
          context.commit("SET_DIAGNOSIS_ID", res.data.id);
          if (detectedScores.length > 0) {
            context.commit("ALLOW_ENTER_RESULT_PAGE");
            router.push("result");
          }
        });
    },
    saveUserProfile(context, payload) {
      Vue.axios.post(`${process.env.VUE_APP_API_SERVER_URL}/user-profiles`, {
        ...payload,
        diagnosisId: context.state.diagnosisId
      });
    },
    signup(context, payload) {
      Vue.axios
        .post(`${process.env.VUE_APP_API_SERVER_URL}/auth/signup`, payload)
        .then(res => {
          localStorage.setItem("authToken", res.data.accessToken);
          context.commit("UPDATE_SIGNUP_FAIL_FLAG", true);
        })
        .catch(() => {
          context.commit("UPDATE_SIGNUP_FAIL_FLAG", true);
        });
    },
    login(context, payload) {
      Vue.axios
        .post(`${process.env.VUE_APP_API_SERVER_URL}/auth/login`, payload)
        .then(res => {
          localStorage.setItem("authToken", res.data.accessToken);
          context.commit("UPDATE_LOGIN_FAIL_FLAG", false);
          location.reload();
        })
        .catch(() => {
          context.commit("UPDATE_LOGIN_FAIL_FLAG", true);
        });
    },
    enquiry(context, payload) {
      Vue.axios
        .post(`${process.env.VUE_APP_API_SERVER_URL}/enquiries`, payload)
        .then(() => {
          context.commit("CAPTURE_ENQUIRY_MESSAGE", true);
        })
        .catch(() => {
          context.commit("CAPTURE_ENQUIRY_MESSAGE", false);
        });
    },
    logout() {
      localStorage.removeItem("authToken");
      location.reload();
    }
  },
  modules: {}
});
